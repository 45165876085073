'use strict';

/**
 * Close Shop Menu Elements
 *
 */
function closeShopByMenuElements() {
    $('.shop-by-menu-wrapper-js').removeClass('open');
    $('.shop-by-menu-mobile-wrapper-js').removeClass('open');
    $('.sub-category-js').removeClass('open');
    $('.subcategory-menu-item').slideUp();
}

/**
 * Close Get Started Menu Elements
 *
 */
function closeGetStartedElements() {
    $('.get-started-js').removeClass('open');
    $('.get-started-trigger-js').removeClass('active');
}

/**
 * Close Project & Resources Menu Elements
 *
 */
function closeProjectsResourcesElements() {
    $('.project-resources-js').removeClass('open');
    $('.project-resources-trigger-js').removeClass('active');
}

/**
 * Shop By Menu Trigger
 *
 */
function shopBy() {
    $(document).on('click', '.shop-by-menu-trigger-js', function (e) {
        e.preventDefault();
        $('.shop-by-menu-wrapper-js').addClass('open');
        closeGetStartedElements();
        closeProjectsResourcesElements();
    });
}

/**
 * Shop By Mobile Menu Trigger
 *
 */
function shopByMobile() {
    $(document).on('click', '.mobile-menu-trigger-js', function (e) {
        e.preventDefault();
        $('.shop-by-menu-mobile-wrapper-js').addClass('open');
        closeGetStartedElements();
        closeProjectsResourcesElements();
    });
}

/**
 * Close Shop By Menu Trigger
 *
 */
function closeShopByMenu() {
    $(document).on('click', '.close-menu-js', function (e) {
        e.preventDefault();
        closeShopByMenuElements();
    });
}

/**
 * DropDown Menu Open
 *
 */
function openDropDownmenu() {
    $(document).on('click', '.dropdown-open-js', function (e) {
        e.preventDefault();
        var $this = $(this);
        var $menuOpen = $this.data('open-menu');
        var $menu = $(`.${$menuOpen}-category`);
        $('.sub-category-js').removeClass('open');
        $menu.addClass('open');
    });
}

/**
 * Sub Category DropDown Menu Open
 *
 */
function subCategaryDropdownOpen() {
    $(document).on('click', '.subcategory-dropdown-open-js', function (e) {
        e.preventDefault();
        var $accordionItem = $(this).parent().parent();
        var $accordionContent = $accordionItem.find('.subcategory-menu-item');
        $(this).toggleClass('active');
        $accordionItem
            .siblings()
            .find('.category-dropdown-open-js')
            .removeClass('active');
        $accordionContent.slideToggle();
    });
}

/**
 * Category DropDown Menu Open
 *
 */
function categoryDropdownOpen() {
    $(document).on('click', '.category-dropdown-open-js', function (e) {
        e.preventDefault();
        var $this = $(this);
        var $accordionItem = $this.parent().parent();
        var $accordionContent = $accordionItem.find('.subcategory-menu-item').eq(0);
        $this.toggleClass('active');
        // Close all items
        // $('.subcategory-menu-item').find('.subcategory-menu-item').slideUp();
        $accordionItem.siblings().find('.subcategory-menu-item').eq(0).slideUp();
        $accordionContent.slideToggle();
    });
}

/**
 * Click Outside Shop By Menu
 *
 */
function clickOutsideMenu() {
    $(document).on('click', '.shop-by-menu-wrapper-js', function (event) {
        // Check if the clicked element is not within the fixed element
        if (
            !$(event.target).closest('.shop-by-menu').length &&
			!$(event.target).closest('.sub-category-js').length
        ) {
            $('.shop-by-menu-wrapper-js').removeClass('open');
            $('.sub-category-js').removeClass('open');
            $('.subcategory-menu-item').slideUp();
            $('.category-dropdown-open-js').removeClass('active');
            $('.subcategory-dropdown-open-js').removeClass('active');
        }
    });
}

/**
 * Get Started Menu
 *
 */
function getStarted() {
    $(document).on('click', '.get-started-trigger-js', function (e) {
        e.preventDefault();
        var $this = $(this);

        $this
            .addClass('active')
            .parent()
            .siblings()
            .find('.active')
            .removeClass('active');
        $('.get-started-js').addClass('open');
        closeShopByMenuElements();
        closeProjectsResourcesElements();
    });
}

/**
 * Click Outside Get Started Menu
 *
 */
function clickOutsideGetStarted() {
    $(document).on('click', '.get-started-js', function (event) {
        // Check if the clicked element is not within the fixed element
        if (!$(event.target).closest('.get-started-wrapper-css').length) {
            closeGetStartedElements();
        }
    });
}

/**
 * Project & Resources Menu
 *
 */
function projectResources() {
    $(document).on('click', '.project-resources-trigger-js', function (e) {
        e.preventDefault();
        var $this = $(this);

        $this
            .addClass('active')
            .parent()
            .siblings()
            .find('.active')
            .removeClass('active');
        $('.project-resources-js').addClass('open');
        closeShopByMenuElements();
        closeGetStartedElements();
    });
}

/**
 * Click Outside Project & Resources Menu
 *
 */
function clickOutsideProjectResources() {
    $(document).on('click', '.project-resources-js', function (event) {
        // Check if the clicked element is not within the fixed element
        if (!$(event.target).closest('.project-resources-wrapper-css').length) {
            closeProjectsResourcesElements();
        }
    });
}

/**
 * Go Back Sub category
 *
 */
function goBackSubCategory() {
    $(document).on('click', '.go-back-subcategory-js', function (e) {
        e.preventDefault();
        var $this = $(this);
        $this.closest('.sub-category-js').removeClass('open');
    });
}

/**
 * Close Shop By Menu Sub Category Trigger
 *
 */
function closeShopByMenuSub() {
    $(document).on('click', '.close-menu-sub-js', function (e) {
        e.preventDefault();
        var $this = $(this);
        $this.closest('.sub-category-js').removeClass('open');
        closeShopByMenuElements();
    });
}

/**
 * Go Back Get Started
 *
 */
function goBackGetStartedCategory() {
    $(document).on('click', '.go-back-getStarted-js', function (e) {
        e.preventDefault();
        var $this = $(this);
        $this.closest('.get-started-js').removeClass('open');
        $('.shop-by-menu-mobile-wrapper-js').addClass('open');
        closeGetStartedElements();
        closeProjectsResourcesElements();
    });
}

/**
 * Close Shop By Menu Get Started Trigger
 *
 */
function closeShopByMenuGetStarted() {
    $(document).on('click', '.close-menu-get-started-js', function (e) {
        e.preventDefault();
        var $this = $(this);
        $this.closest('.get-started-js').removeClass('open');
    });
}

/**
 * Go Back Projects & resources
 *
 */
function goBackProjectsCategory() {
    $(document).on('click', '.go-back-projects-js', function (e) {
        e.preventDefault();
        var $this = $(this);
        $this.closest('.project-resources-js').removeClass('open');
        $('.shop-by-menu-mobile-wrapper-js').addClass('open');
        closeGetStartedElements();
        closeProjectsResourcesElements();
    });
}

/**
 * Close Shop By Menu Projects & resources Trigger
 *
 */
function closeShopByMenuProjects() {
    $(document).on('click', '.close-menu-projects-js', function (e) {
        e.preventDefault();
        var $this = $(this);
        $this.closest('.project-resources-js').removeClass('open');
    });
}

/**
 * Sticky Header Function
 *
 */
function initScrollTracking() {
    $(document).ready(function () {
        const toggleClass = 'is-sticky';
        $(window).on('scroll', function () {
            const currentScroll = $(this).scrollTop();

            if (currentScroll > 100) {
                $('body').addClass(toggleClass);
            } else {
                $('body').removeClass(toggleClass);
            }
        });
    });
}

/**
 * Close Side Panel Function
 *
 */
function closeSidePanel() {
    $(document).ready(function () {
        $(document).on('click', '.side-panels', function (e) {
            if ($(e.target).hasClass('show-module')) {
                $(e.target).removeClass('show-module');
                $('html, body').removeAttr('style');
                $('body, html').removeClass('sidepanel-open');
            }
        });
    });
}

module.exports = {
    shopBy,
    shopByMobile,
    closeShopByMenu,
    openDropDownmenu,
    subCategaryDropdownOpen,
    categoryDropdownOpen,
    clickOutsideMenu,
    clickOutsideGetStarted,
    clickOutsideProjectResources,
    getStarted,
    projectResources,
    goBackSubCategory,
    closeShopByMenuSub,
    closeShopByMenuGetStarted,
    goBackGetStartedCategory,
    goBackProjectsCategory,
    closeShopByMenuProjects,
    initScrollTracking,
    closeSidePanel,
};
