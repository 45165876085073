'use strict';

var formValidation = require('base/components/formValidation');
var createErrorNotification = require('base/components/errorNotification');
var clientSideValidation = require('../components/clientSideValidation');

var location = window.location;

/**
 * function used to fetch all the interested options selected by the user
 * @returns {Object[]} - interestedIn - array of selected interested options
 */
function fetchAllInterestedData() {
    var interestedIn = [];
    $('.js-interested-in-section li.selected').each(function () {
        interestedIn.push($(this).data('value'));
    });
    return JSON.stringify(interestedIn);
}

module.exports = {
    login: function () {
        $('form.login').submit(function (e) {
            var form = $(this);
            e.preventDefault();
            var url = form.attr('action');
            var recaptchaSiteKey = form.find('button').data('site-key');
            var formData = form.serialize();
            form.spinner().start();
            $('form.login').trigger('login:submit', e);
            // eslint-disable-next-line no-undef
            grecaptcha.ready(function () {
                // eslint-disable-next-line no-undef
                grecaptcha
                    .execute(recaptchaSiteKey, { action: 'submit' })
                    .then(function (token) {
                        // Add your logic to submit to your backend server here.
                        formData += '&recaptchaToken=' + token;
                        $.ajax({
                            url: url,
                            type: 'post',
                            dataType: 'json',
                            data: formData,
                            success: function (data) {
                                if (!data.success) {
                                    form.spinner().stop();
                                    formValidation(form, data);
                                    $('form.login').trigger('login:error', data);
                                } else {
                                    $('form.login').trigger('login:success', data);
                                    location.href = data.redirectUrl;
                                }
                            },
                            error: function (data) {
                                if (data.responseJSON.redirectUrl) {
                                    window.location.href = data.responseJSON.redirectUrl;
                                } else {
                                    $('form.login').trigger('login:error', data);
                                    form.spinner().stop();
                                }
                            },
                        });
                    });
            });
            return false;
        });
    },

    register: function () {
        $('form.registration').submit(function (e) {
            var form = $(this);
            e.preventDefault();
            var url = form.attr('action');
            form.spinner().start();
            $('form.registration').trigger('login:register', e);

            if (!clientSideValidation.functions.isRegistrationValid(form, e)) {
                form.spinner().stop();
                return false;
            }

            var recaptchaSiteKey = form.find('button').data('site-key');
            var formData =
				form.serialize() + '&interestedIn=' + fetchAllInterestedData();
            // eslint-disable-next-line no-undef
            grecaptcha.ready(function () {
                // eslint-disable-next-line no-undef
                grecaptcha
                    .execute(recaptchaSiteKey, { action: 'submit' })
                    .then(function (token) {
                        // Add your logic to submit to your backend server here.
                        formData += '&recaptchaToken=' + token;
                        $.ajax({
                            url: url,
                            type: 'post',
                            dataType: 'json',
                            data: formData,
                            success: function (data) {
                                form.spinner().stop();
                                if (!data.success) {
                                    $('form.registration').trigger('login:register:error', data);
                                    formValidation(form, data);
                                } else {
                                    $('form.registration').trigger(
                                        'login:register:success',
                                        data
                                    );
                                    location.href = data.redirectUrl;
                                }
                            },
                            error: function (err) {
                                if (err.responseJSON.redirectUrl) {
                                    window.location.href = err.responseJSON.redirectUrl;
                                } else {
                                    createErrorNotification(
                                        $('.error-messaging'),
                                        err.responseJSON.errorMessage
                                    );
                                }

                                form.spinner().stop();
                            },
                        });
                    });
            });
            return false;
        });
    },

    resetPassword: function () {
        $('.reset-password-form').submit(function (e) {
            var form = $(this);
            e.preventDefault();
            var url = form.attr('action');
            form.spinner().start();
            $('.reset-password-form').trigger('login:register', e);
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: form.serialize(),
                success: function (data) {
                    form.spinner().stop();
                    if (!data.success) {
                        formValidation(form, data);
                    } else {
                        $('.request-password-title').text(data.receivedMsgHeading);
                        $('.request-password-body')
                            .empty()
                            .append('<p>' + data.receivedMsgBody + '</p>');
                        if (!data.mobile) {
                            $('#submitEmailButton')
                                .text(data.buttonText)
                                .attr('data-dismiss', 'modal');
                        } else {
                            $('.send-email-btn')
                                .empty()
                                .html(
                                    '<a href="' +
										data.returnUrl +
										'" class="btn btn-primary btn-block">' +
										data.buttonText +
										'</a>'
                                );
                        }
                    }
                },
                error: function () {
                    form.spinner().stop();
                },
            });
            return false;
        });
    },

    clearResetForm: function () {
        $('#login .modal').on('hidden.bs.modal', function () {
            $('#reset-password-email').val('');
            $('.modal-dialog .form-control.is-invalid').removeClass('is-invalid');
        });
    },

    handleInterestedInSelection: function () {
        $('.js-interested-in-section li').on('click', function () {
            $(this).toggleClass('selected');
        });
    },
};
